import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbBanner from "../components/BreadcrumbBanner";
import SplitContentImage from "../components/SplitContentImage";
import React from "react";
import { SectionSpacer } from "../feature/blog/layout/SectionSpacer";
import { graphql } from "gatsby";
import { FeatureList } from "../components/FeatureList";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "../components/SplitContentImage.module.scss";

const About = ({ data }) => {
  console.log(data);
  return (
    <>
      <SEO title="About HueBox" />
      <Layout>
        <BreadcrumbBanner
          title="About HueBox"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="About HueBox"
        />
        <div className="main-content">
          <div className="container">
            <SectionSpacer fullWidth>
              <SplitContentImage
                inContainer
                title="HueBox is an immersive experience that lights up your brand"
                image={
                  <GatsbyImage
                    image={data.immersiveImage.childImageSharp.gatsbyImageData}
                  />
                }
              >
                <p>
                  HueBox is the worlds most dynamic programmable LED lightbox
                  system. Capturing attention by coming to life through light
                  and sound to reveal a hidden side, a new dimension or
                  highlight of a product feature, driving drama and intrigue to
                  immerse your customers in your brand or product.
                </p>
              </SplitContentImage>

              <SplitContentImage
                inContainer
                title="Strong, versatile, go anywhere"
                image={
                  <GatsbyImage
                    image={data.systemImage.childImageSharp.gatsbyImageData}
                  />
                }
                reverse
              >
                <p>
                  Designed around its 120mm modular aluminium profile holding
                  stretched silicon edge fabric graphics, HueBox light boxes can
                  stand on their own feet, be wall mounted or even suspended in
                  a range of configurations including advanced ceiling lighting.
                </p>
                <h4 className={styles.title}>
                  One system, infinite display options
                </h4>
                <p>
                  Lighting scenes using dynamic LED lightboxes can be changed by
                  simply updating the program to deliver the desired effect.
                </p>
                <p>
                  They can powerfully complement any existing interior design or
                  colour scheme to deliver a stunning promotional experience.
                </p>
              </SplitContentImage>

              <SplitContentImage
                inContainer
                image={
                  <GatsbyImage
                    image={data.featuresImage.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                  />
                }
              >
                <FeatureList
                  items={[
                    {
                      title: "Flexible design for any environment",
                      content: `Huebox modular LED Lightboxes can be easily designed and
                programmed for any environment from Airports to Exhibitions.`,
                    },
                    {
                      title: "Stand out from the crowd",
                      content: `Create a visually stunning display that puts your competitors in
                the shade.`,
                    },
                    {
                      title: "Easy installation",
                      content: `Huebox is a modular system that is easily installed.`,
                    },
                  ]}
                />
              </SplitContentImage>
            </SectionSpacer>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default About;
export const query = graphql`
  {
    immersiveImage: file(
      relativePath: {
        eq: "Hue_Box_LEDs_ON_Colour_Test_Gradient_Material_LONG_Material_OFF.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    systemImage: file(
      relativePath: { eq: "Hue_Box_LEDs_ON_Cross_Section_cropped.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    featuresImage: file(relativePath: { eq: "christmas-dog-house.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`;
